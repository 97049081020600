<template>
  <div class="form-container">
    <h5 class="main-title">관리자 정보 관리</h5>
    <div class="info-flex">
      <div>
        <div class="bs-flex">
          <h5 class="info-title">관리자명</h5>
          <input type="text" class="custom-input-box w654" placeholder="관리자명 입력" v-model="userInfo.name"/>
        </div>
        <div class="bs-flex">
          <h5 class="info-title">생년월일</h5>
          <mdb-date-picker-2 class="input-box bg-white search-select" outline placeholder="날짜 선택" type="text" size="lg" v-model="userInfo.birth" title="생년월일 선택" :options="options"/>
        </div>
        <div class="bs-flex">
          <h5 class="info-title">성별</h5>
          <mdb-form-inline>
            <mdb-input type="radio" id="gender-1" name="groupOfMaterialRadios2" radioValue="남성" v-model="userInfo.gender"
                       label="남성"/>
            <mdb-input type="radio" id="gender-2" name="groupOfMaterialRadios2" radioValue="여성" v-model="userInfo.gender"
                       label="여성"/>
          </mdb-form-inline>
        </div>
        <div class="bs-flex">
          <h5 class="info-title">소속병원</h5>
          <input type="text" class="custom-input-box w654" placeholder="소속병원명 입력" v-model="userInfo.hospitalName"/>
        </div>

        <div class="bs-flex">
          <h5 class="info-title">이메일</h5>
          <input type="text" class="custom-input-box w237" placeholder="이메일 입력" v-model="userInfo.email"/>
        </div>
        <div class="bs-flex">
          <h5 class="info-title">휴대전화번호</h5>
          <input type="text" class="custom-input-box w237" placeholder="휴대전화번호 입력" v-model="userInfo.mobile"/>
        </div>
      </div>
    </div>
    <hr/>
    <div class="bs-flex">
      <button class="btn-add" @click="updateUserInfo">수정</button>
    </div>
    <h5 class="main-title">비밀번호 변경 </h5>
    <div class="bs-flex">
      <h5 class="info-title">변경 비밀번호 입력</h5>
      <input type="password" class="custom-input-box w654" placeholder="비밀번호 8자리 이상" v-model="password"/>
    </div>
    <div class="bs-flex mg-t10">
      <h5 class="info-title">변경 비밀번호 확인</h5>
      <input type="password" class="custom-input-box w237" placeholder="비밀번호 8자리 이상" v-model="repassword"/>
    </div>
    <hr>
    <div class="bs-flex">
      <button class="btn-pw" @click="updatePassword">비밀번호 변경</button>
    </div>
  </div>
</template>

<script>
import {mdbDatePicker2, mdbFormInline, mdbInput} from "mdbvue";
import {firebase} from "../../firebase/firebaseConfig";

export default {
  name: "MasterInfo",
  components: {
    mdbInput,
    mdbFormInline,
    mdbDatePicker2,
  },
  data() {
    return {
      fbCollection: 'master',
      fbUid: this.$store.state.user.uid,
      yearOptions: [],
      monthOptions: [],
      dayOptions: [],
      hospitalTypeOptions: [],
      hospitalLocationOptions: [],
      jobOptions: [],
      workingYearOptions: [],
      isShowModal: false,
      birthYear: '',
      birthMonth: '',
      birthDay: '',
      password: '',
      repassword: '',
      userInfo: {
        birth: '',
        email: '',
        gender: '',
        mobile: '',
        name: '',
      },
      options: {
        month: [
          '1월',
          '2월',
          '3월',
          '4월',
          '5월',
          '6월',
          '7월',
          '8월',
          '9월',
          '10월',
          '11월',
          '12월'
        ],
        week: ['일', '월', '화', '수', '목', '금', '토'],
        color: {
          header: 'info',
          headerText: 'white',
          checkedDay: 'info'
        },
        buttons: {
          ok: '확인',
          cancel: '취소',
          clear: '초기화'
        },
      },
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    updateUserInfo() {
      const self = this
      const db = firebase.firestore();

      const defRef = db.collection(self.fbCollection);
      defRef.doc(self.fbUid)
          .update(self.userInfo)
          .then(() => {
            alert('변경되었습니다.');
          });
    },
    initData() {
      const self = this
      const db = firebase.firestore();
      const defRef = db.collection(self.fbCollection);
      defRef.doc(self.fbUid)
          .get()
          .then((querySnapshot) => {
            self.userInfo = querySnapshot.data();
          });
    },
    updatePassword() {
      const self = this;
      if (self.password.trim().length === 0) {
        alert('비밀번호를 입력하세요.')
        return false
      } else if (self.repassword.trim() !== self.password.trim()) {
        alert('패스워드가 일치하지 않습니다.')
        return false
      } else if (self.repassword.trim().length < 8) {
        alert('비밀번호는 8자리 이상으로 입력해주세요.')
        return false
      }

      const user = firebase.auth().currentUser;
      const newPassword = self.password;

      user.updatePassword(newPassword).then(() => {
        alert('패스워드가 변경되었습니다.')
      }).catch((error) => {
        self.firebaseError(error)
      });
      // [END auth_update_password]
    },

    firebaseError(error) {
      if ('auth/invalid-verification-code' === error.code) {
        alert('인증번호가 유효하지 않습니다.');
      } else if ('auth/session-expired' === error.code) {
        alert('인증번호가 만료되었습니다.');
      } else if ('auth/too-many-requests' === error.code) {
        alert('잠시 후 다시 시도해 주세요.');
      } else if ('Error: The email address is badly formatted.' === error.code) {
        alert('이메일은 필수입니다.');
      } else if ('auth/requires-recent-login' === error.code) {
        alert('이 작업은 민감하며 최근 인증이 필요합니다. 이 요청을 다시 시도하기 전에 다시 로그인하십시오.');
      } else {
        console.log(error);
      }
    },
  }
}
</script>

<style scoped>
.form-container {
  background: white;
  padding: 56px 38px;
}

.main-title {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 30px;
}

.bs-flex {
  display: flex;
  flex-direction: row;
}

.info-flex {
  display: flex;
  flex-direction: row;
}

.info-flex .bs-flex {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}

.info-title {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 40px;
  letter-spacing: 0.0015em;
  color: #272833;
  width: 150px;
  margin-right: 10px;
}

hr {
  position: relative;
  padding: 0 !important;
  margin: 11px 0 36px 0;
  border-top: 2px solid #E1E3E6;
}

/deep/ .caret {
  display: none;
}

.search-select {
  margin-right: 10px;
}

/deep/ .search-select .md-form {
  margin: 0 !important;
}

/deep/ .search-select .form-control {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  height: 40px;
  letter-spacing: 0.0025em;
  color: #999999;
  margin: 0;
}

.w654 {
  width: 654px;
}

.w237 {
  width: 237px;
}

.btn-add {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  width: 116px;
  height: 46px;
  background: #0069CA;
  border-radius: 4px;
  border: 0;
  margin-left: auto;
}

.btn-pw {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  width: 116px;
  height: 46px;
  background: #ca0000;
  border-radius: 4px;
  border: 0;
  margin-left: auto;
}

/deep/ .custom-input-box::placeholder {
  font-size: 16px !important;
}

/deep/ label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: #000000;
  margin: 0;
}

.mg-t10 {
  margin-top: 25px;
}
</style>
